import React from "react";
import { Routes, Route } from "react-router-dom";

import { AppRoutings } from "utility/enums/app-routings";
import Home from "components/Home";
import Community from "components/Community";

const RouterConfig: React.FC = () => {
  return (
    <Routes>
      <Route path={AppRoutings.Root} element={<Home />} />
      <Route path={AppRoutings.Community} element={<Community />} />
    </Routes>
  );
};

export default RouterConfig;

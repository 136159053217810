import React from 'react'

const Loading: React.FC = () => {
  return (
    <div id="preloader">
		<div id="status">&nbsp;</div>
	</div>
  )
}

export default Loading
import React from "react";
import { faEnvelope, faMobile  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactUs: React.FC = () => {
  return (
    <section id="contact">
      <div className="contact-box">
        <div className="container">
          <div className="row display-flex">
            <div className="col-md-6">
              {/* Contact Left */}
              <div id="contact-left">
                <div className="vertical-heading">
                  <h5>Who We Are</h5>
                  <h2>
                    Get
                    <br />
                    In <strong>Touch</strong>
                  </h2>
                </div>
                <p>
                  We'd love to hear from you! Whether you have questions,
                  feedback, or need assistance, our team is here to help. Please
                  contact us through one of the methods listed.
                </p>
              </div>
            </div>
            <div
              className="col-md-6"
            >
              {/* Contact Right */}
              <div id="contact-right">
                <div id="offices">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="office">
                        <h4>India</h4>
                        <ul className="office-details">
                          <li>
                            <FontAwesomeIcon
                              icon={faMobile}
                              style={{ marginRight: "10px" }}
                            />
                            <span>+91 9099278084</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="office">
                        <h4>Canada</h4>
                        <ul className="office-details">
                          <li>
                            <FontAwesomeIcon
                              icon={faMobile}
                              style={{ marginRight: "10px" }}
                            />
                            <span>+1 4168314904</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="office">
                        <ul className="office-details">
                        <li>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              style={{ marginRight: "10px" }}
                            />
                            <span>contact@theoltcompany.com</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <ul className="social-list">
                  <li>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{ marginRight: "10px" }}
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{ marginRight: "10px" }}
                    />
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faGooglePlus}
                      style={{ marginRight: "10px" }}
                    />
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";

import CustomRoutes from "components/CustomRoutes";
import Loading from "components/Loading";
import "../../index.css";
import "../../responsive.css";
import Footer from "components/Layout/footer";
import Header from "components/Layout/header";

const App: React.FC = () => {
  const [isScroll, setIsScroll] = useState(false);

  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <div style={{ overflow: "hidden" }}>
          <Header setIsScroll={setIsScroll} />
          <CustomRoutes />
          <Footer isScroll={isScroll} />
        </div>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;

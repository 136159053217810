import React from "react";

import { ServicesImage1, ServicesImage2 } from "assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptop,
  faLaptopCode,
  faPaintBrush,
  faPaperPlane,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <section id="services">
      {/* Services 01 */}
      <div id="services-01">
        <div className="content-box-lg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center wow slideInDown">
                <div className="horizontal-heading">
                  <h5>What We Do</h5>
                  <h2>Our Services</h2>
                </div>
                <p>
                  At The OLT Company, we offer a comprehensive suite of services
                  tailored to meet your unique needs. Our solutions are designed
                  to drive growth, enhance user experiences, and ensure robust
                  security.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5 col-sm-5 wow slideInLeft">
                <img
                  src={ServicesImage1}
                  alt="laptop"
                  className="img-responsive"
                />
              </div>
              <div className="col-md-7 col-sm-7 wow slideInRight">
                {/* Service 01 */}
                <div className="service">
                  <div className="row">
                    <div className="col-md-2 col-sm-2">
                      <div className="icon text-right">
                        <FontAwesomeIcon icon={faPaintBrush} />
                      </div>
                    </div>

                    <div className="col-md-10 col-sm-10">
                      <h5>Service 01</h5>
                      <h4>Web Development</h4>
                      <p>
                        Transform your online presence with our bespoke web
                        development services. Leveraging the latest technologies
                        and best practices, we build responsive, scalable, and
                        user-friendly websites that captivate and convert.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Service 02 */}
                <div className="service">
                  <div className="row">
                    <div className="col-md-2 col-sm-2">
                      <div className="icon text-right">
                        <FontAwesomeIcon icon={faLaptop} />
                      </div>
                    </div>

                    <div className="col-md-10 col-sm-10">
                      <h5>Service 02</h5>
                      <h4>Software Development</h4>
                      <p>
                        From concept to deployment, our software development
                        team crafts custom solutions that address your specific
                        challenges. We focus on delivering high-quality,
                        scalable, and efficient software tailored to your needs.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Service 03 */}
                <div className="service">
                  <div className="row">
                    <div className="col-md-2 col-sm-2">
                      <div className="icon text-right">
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </div>
                    </div>

                    <div className="col-md-10 col-sm-10">
                      <h5>Service 03</h5>
                      <h4>UI/UX Design</h4>
                      <p>
                        Create memorable digital experiences with our UI/UX
                        design services. We prioritize user-centered design to
                        ensure that your applications are not only visually
                        appealing but also intuitive and engaging.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-7 col-sm-7 wow slideInLeftt">
                {/* Service 01 */}
                <div className="service">
                  <div className="row">
                    <div className="col-md-2 col-sm-2">
                      <div className="icon text-right">
                        <FontAwesomeIcon icon={faLaptopCode} />
                      </div>
                    </div>

                    <div className="col-md-10 col-sm-10">
                      <h5>Service 04</h5>
                      <h4>Testing and QA</h4>
                      <p>
                        Guarantee the reliability and performance of your
                        software with our comprehensive testing and QA services.
                        We identify and address potential issues before they
                        affect your users, ensuring a flawless experience.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Service 02 */}
                <div className="service">
                  <div className="row">
                    <div className="col-md-2 col-sm-2">
                      <div className="icon text-right">
                        <FontAwesomeIcon icon={faRobot} />
                      </div>
                    </div>

                    <div className="col-md-10 col-sm-10">
                      <h5>Service 05</h5>
                      <h4>Cyber Security</h4>
                      <p>
                        Protect your digital assets with our advanced
                        cybersecurity solutions. Our services include CTF
                        development and automation testing, providing you with a
                        robust defense against emerging threats.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-5 wow slideInRigh">
                <img
                  src={ServicesImage2}
                  alt="laptop"
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services 01 Ends */}
    </section>
  );
};

export default Services;

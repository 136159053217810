import React from "react";

import AboutUs from "./aboutUs";
import Events from "./events";
import ConnectUs from "./connectUs";

const Community: React.FC = () => {
  return (
    <>
      <AboutUs />
      <Events />
      <ConnectUs />
    </>
  );
};

export default Community;

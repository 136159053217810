import React from "react";

const AboutUs: React.FC = () => {
  return (
    <section id="community-about">
      {/* About 01 */}
      <div id="about-01">
        <div className="content-box-lg">
          <div className="container">
            <div className="row">
              {/* About Left Side */}
              <div
                className="col-md-6 col-sm-6 wow slideInLeft"
                data-wow-duration="1s"
              >
                <div id="about-left">
                  <div className="vertical-heading">
                    <h5>Who We Are</h5>
                    <h2>
                      <strong>About</strong>
                      <br />
                      Us
                    </h2>
                  </div>
                </div>
              </div>

              {/* About Right Side */}
              <div
                className="col-md-6 col-sm-6 wow slideInRight"
                data-wow-duration="1s"
              >
                <div id="about-right">
                  <p>
                    Dive deeper into your tech journey with us! Our community
                    offers a wealth of resources, from curated learning
                    materials to workshops led by experts. Collaborate on
                    projects, build connections at meetups, and even contribute
                    to open-source initiatives. This is your chance to learn,
                    grow, and be part of something bigger alongside a passionate
                    community.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 01 Ends */}

      {/* About 02 */}
      <div id="about-02">
        <div className="content-box-md">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center wow slideInDown">
                <div className="horizontal-heading">
                  <h2>
                    <strong>Why Join Us?</strong>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row display-flex">
              <div
                className="col-md-3 col-xs-12 col-sm-6 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Skill Development</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Mentorship Program</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6 wow fadeInLeft"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center padding-41">
                  <h3>Project Showcase & Contribute</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center padding-41">
                  <h3> Launchpad for Tech Ambitions</h3>
                </div>
              </div>
            </div>

            <br />

            <div className="row">
              <div
                className="col-md-3 col-xs-12 col-sm-6 wow fadeInLeft"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Events & Workshops</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Networking Oppurtunity</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Career Development</h3>
                </div>
              </div>
              <div
                className="col-md-3 col-xs-12 col-sm-6  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item text-center">
                  <h3>Startup Incubation</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 02 Ends */}
    </section>
  );
};

export default AboutUs;

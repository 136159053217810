import React, { useState } from "react";

const ConnectUs: React.FC = () => {
  const [result, setResult] = useState("");
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Helper function for email validation with strict pattern and temporary email check
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const temporaryEmailDomains = ["tempmail.com", "mailinator.com", "10minutemail.com", "guerrillamail.com"];
    const domain = email.split("@")[1];

    return emailRegex.test(email.trim()) && !temporaryEmailDomains.includes(domain);
  };

  // Helper function for name validation (only letters and spaces, min 2 characters)
  const isValidName = (name: string) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name.trim()) && name.trim().length >= 2;
  };

  // Helper function for message validation (at least 50 characters)
  const isValidMessage = (message: string) => {
    return message.trim().length >= 50;
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setResult("Sending...");

    const formData = new FormData(event.target as HTMLFormElement);

    // Validate form inputs
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const message = formData.get("message") as string;

    const errors = {
      name: isValidName(name) ? "" : "Please enter a valid name (letters and spaces only, min 2 characters).",
      email: isValidEmail(email) ? "" : "Please enter a valid email address (no temporary emails allowed).",
      message: isValidMessage(message) ? "" : "Message should be at least 50 characters long.",
    };

    setFormErrors(errors);

    // Prevent form submission if there are errors
    if (errors.name || errors.email || errors.message) {
      setResult("Please correct the errors in the form.");
      return;
    }

    formData.append("access_key", "d02d4762-0c0f-46ea-8ad5-3dbd5614a37e"); // Replace with your actual Web3Forms access key

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      (event.target as HTMLFormElement).reset();
    } else {
      console.error("Error:", data);
      setResult(data.message);
    }
  };

  return (
    <section id="contact">
      <div className="content-box-lg" style={{ paddingTop: "20px" }}>
        <div className="container" style={{ display: "flex", justifyContent: "center" }}>
          <div className="row">
            <div className="col-md-12 text-center wow slideInDown">
              <div id="contact-01">
                <div className="horizontal-heading">
                  <h5>How can you communicate?</h5>
                  <h2>Contact Us</h2>
                </div>
                <div style={{ width: "100%" }}>
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Your Name"
                            required
                          />
                          {formErrors.name && (
                            <small className="text-danger">{formErrors.name}</small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email Address"
                            required
                          />
                          {formErrors.email && (
                            <small className="text-danger">{formErrors.email}</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        rows={5}
                        required
                      ></textarea>
                      {formErrors.message && (
                        <small className="text-danger">{formErrors.message}</small>
                      )}
                    </div>

                    <div id="submit-btn">
                      <button
                        type="submit"
                        className="btn btn-general btn-yellow"
                        title="Submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <span>{result}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectUs;
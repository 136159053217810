export const getImagePath = (imageNameWithExtension: string): string => {
	return require(`./${imageNameWithExtension}`);
};

export const Preloader = getImagePath("preloader.gif");
export const HeaderBg = getImagePath("header-bg.png");
export const Background = getImagePath("Background.png");

// TOC logo
export const TOC_Logo = getImagePath("TOC_Logo.svg");
export const Logo_BW = getImagePath("Logo_BW.png");
export const BW_Logo = getImagePath("Logo_BW.svg");
export const BW_TOC = getImagePath("black-white-logo-TOC.png");
export const Black_TOC = getImagePath("black-logo-TOC.png");
export const Blue_TOC = getImagePath("blue-logo-TOC.png");
export const White_TOC = getImagePath("white-logo-TOC.png");

// TOC site 
export const AboutImage = getImagePath("about.png");
export const ServicesImage1 = getImagePath("services-1.png");
export const ServicesImage2 = getImagePath("services-2.png");

// community logo
export const CommunityLogo = getImagePath("community-logo.png");
export const CommunityLogoWhite = getImagePath("community-logo-white.png");

// community site 
export const CommunityBg = getImagePath("community-bg.png");
export const Docker = getImagePath("docker.png");
export const Resume = getImagePath("resume-writing.png");
export const CyberSecurity = getImagePath("cyber-security.png");
export const ComingSoon = getImagePath("coming-soon.png");

import React from "react";

import AboutUs from "./aboutUs";
// import Team from "./team";
import Services from "./services";
// import Portfolio from "./portfolio";
// import Blog from "./blog";
import ContactUs from "./contactUs";

const Home: React.FC = () => {
  return (
    <>
      <AboutUs />
      {/* <Team /> */}
      <Services />
      {/* <Portfolio /> */}
      {/* <Blog /> */}
      <ContactUs />
    </>
  );
};

export default Home;

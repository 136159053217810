import React from "react";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

const Footer: React.FC<{ isScroll: boolean }> = ({ isScroll }) => {
  const location = useLocation();

  return (
    <footer className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
              Copyright &copy; 2024 All Rights Reserved By{" "}
              <span>The OLT Company Inc.</span>
            </p>
          </div>
        </div>
      </div>

      {/* Back To Top */}
      <a
        href={location.pathname.includes("community") ? "/community" : "/#home"}
        id="back-to-top"
        className="btn btn-sm btn-yellow btn-back-to-top smooth-scroll hidden-sm hidden-xs"
        title="home"
        role="button"
        style={{ display: isScroll ? "block" : "none" }}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </a>
    </footer>
  );
};

export default Footer;

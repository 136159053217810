import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Background,
  Blue_TOC,
  CommunityBg,
  CommunityLogo,
  CommunityLogoWhite,
  White_TOC,
} from "assets";

const Header: React.FC<{
  setIsScroll: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsScroll }) => {
  const location = useLocation();
  // Add and Remove Class on scroll
  const [scrollTop, setScrollTop] = useState("");
  const [openMobileNav, setOpenMobileNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setScrollTop("");
      } else {
        setScrollTop("white-nav-top");
        setIsScroll(true);
      }
    });
  }, []);

  const isCommunityPage = useMemo(() => {
    return location.pathname.includes("community");
  }, [location]);

  return (
    <>
      <header>
        <nav className={`navbar navbar-fixed-top ${scrollTop}`}>
          <div className="container-fluid">
            <div className="site-nav-wrapper">
              <div className="navbar-header">
                {/* Mobile Menu Open Button */}
                <span
                  id="mobile-nav-open-btn"
                  onClick={() => setOpenMobileNav(true)}
                >
                  &#9776;
                </span>

                {/* Logo */}
                <a className="navbar-brand smooth-scroll" href="#home">
                  <img
                    src={
                      isCommunityPage
                        ? scrollTop
                          ? CommunityLogo
                          : CommunityLogoWhite
                        : scrollTop
                        ? Blue_TOC
                        : White_TOC
                    }
                    alt="logo"
                    width={isCommunityPage ? "40" : "50"}
                    height={isCommunityPage ? "40" : "50"}
                    style={{ marginTop: isCommunityPage ? "5px" : "" }}
                  />
                </a>
              </div>

              {/* Main Menu */}
              <div className="container">
                <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav pull-right">
                    <li>
                      <a className="smooth-scroll" href="/#home">
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/#about">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/#services">
                        Services
                      </a>
                    </li>
                    {/* <li>
                      <a className="smooth-scroll" href="/#portfolio">
                        Work
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="smooth-scroll" href="/#team">
                        Team
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="smooth-scroll" href="/#blog">
                        Blog
                      </a>
                    </li> */}
                    <li>
                      <a className="smooth-scroll" href="/#contact">
                        Contact
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/community">
                        Community
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Mobile Menu */}
              <div
                id="mobile-nav"
                style={{ height: openMobileNav ? "100%" : "0%" }}
              >
                {/* Mobile Menu Close Button */}
                <span
                  id="mobile-nav-close-btn"
                  onClick={() => setOpenMobileNav(false)}
                >
                  &times;
                </span>

                <div id="mobile-nav-content">
                  <ul className="nav">
                    <li>
                      <a className="smooth-scroll" href="/#home">
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/#about">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/#services">
                        Services
                      </a>
                    </li>
                    {/* <li>
                      <a className="smooth-scroll" href="/#portfolio">
                        Work
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="smooth-scroll" href="/#team">
                        Team
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="smooth-scroll" href="/#blog">
                        Blog
                      </a>
                    </li> */}
                    <li>
                      <a className="smooth-scroll" href="/#contact">
                        Contact
                      </a>
                    </li>
                    <li>
                      <a className="smooth-scroll" href="/community">
                        Community
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Home */}
      <section id="home">
        {/* Overlay */}
        <div
          id="home-overlay"
        >
          <img src={Background} alt="" width="100%" height="100%"   />
        </div>

        {isCommunityPage ? (
          <div id="home-content">
            <div id="home-content-inner" className="text-center">
              <div id="home-heading" className="home-heading-img" >
                <img src={CommunityBg} alt="" style={{ objectFit: "cover" }}  />
              </div>
            </div>
          </div>
        ) : (
          <div id="home-content">
            <div id="home-content-inner" className="text-center">
              <div id="home-heading">
                <h1 id="home-heading-1">The OLT Company</h1>
                <br />
                <h1 id="home-heading-2">
                  <span>Innovate, Elevate, Succeed</span>
                </h1>
              </div>

              <div id="home-text">
                <p>
                  Bridging Creativity and Technology to Transform Your Digital
                  Presence.
                </p>
              </div>

              <div id="home-btn">
                <a
                  className="btn btn-general btn-home smooth-scroll"
                  href="/#about"
                  title="Start Now"
                  role="button"
                >
                  Start Now
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Arrow Down */}
        {/* <a href="/#about" id="arrow-down" className="smooth-scroll">
          <FontAwesomeIcon icon={faAngleDown} />
        </a> */}
      </section>
      {/* Home Ends */}
    </>
  );
};

export default Header;

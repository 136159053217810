import React from "react";
import { faEye, faPencil, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AboutImage } from "assets";

const AboutUs: React.FC = () => {
  return (
    <section id="about">
      {/* About 01 */}
      <div id="about-01">
        <div className="content-box-lg">
          <div className="container">
            <div className="row">
              {/* About Left Side */}
              <div
                className="col-md-6 col-sm-6 wow slideInLeft"
                data-wow-duration="1s"
              >
                <div id="about-left">
                  <div className="vertical-heading">
                    <h5>Who We Are</h5>
                    <h2>
                      A <strong>Story</strong>
                      <br />
                      About Us
                    </h2>
                  </div>
                </div>
              </div>

              {/* About Right Side */}
              <div
                className="col-md-6 col-sm-6 wow slideInRight"
                data-wow-duration="1s"
              >
                <div id="about-right">
                  <p>
                    Founded with a vision to reshape the tech landscape, The OLT
                    Company (TOC) is committed to delivering exceptional
                    solutions that empower businesses and individuals alike.
                  </p>

                  <ul>
                    <li>
                      <strong>Innovation</strong>: We embrace the latest
                      technologies to deliver cutting-edge solutions.{" "}
                    </li>
                    <li>
                      <strong>Excellence</strong>: We strive for the highest
                      standards in every project.
                    </li>
                    <li>
                      <strong>Customer Centricity</strong>: Your success is our
                      top priority.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* About Bottom */}
            {/* <div className="row">
              <div className="col-md-12 wow fadeInUp" data-wow-duration="2s">
                <div id="about-bottom">
                  <img
                    src={AboutImage}
                    alt="About Us"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* About 01 Ends */}

      {/* About 02 */}
      <div id="about-02">
        <div className="content-box-md">
          <div className="container">
            <div className="row display-flex">
              <div
                className="col-md-4 col-sm-12 wow fadeInLeft"
                style={{ flexBasis: "fit-content" }}
              >
                {/* About item 01 */}
                <div className="about-item text-center">
                  <FontAwesomeIcon
                    icon={faRocket}
                    style={{ marginRight: "10px" }}
                    size="2xl"
                  />
                  <h3>Mission</h3>
                  <hr />
                  <p>
                    To provide transformative tech solutions that drive success
                    and foster growth in an ever-evolving digital world.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                {/* About item 02 */}
                <div className="about-item text-center">
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ marginRight: "10px" }}
                    size="2xl"
                  />
                  <h3>Core Values</h3>
                  <hr />
                  <p>
                    Our journey is driven by innovation, passion, and a
                    relentless pursuit of excellence.
                  </p>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                {/* About item 03 */}
                <div className="about-item text-center">
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{ marginRight: "10px" }}
                    size="2xl"
                  />
                  <h3>Why Choose Us</h3>
                  <hr />
                  <p>
                    With a team of seasoned professionals and a commitment to
                    excellence, we are your trusted partner in navigating the
                    complexities of technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 02 Ends */}
    </section>
  );
};

export default AboutUs;

import { ComingSoon, CyberSecurity, Docker, Resume } from "assets";
import React from "react";

const Events: React.FC = () => {
  return (
    <section id="about">
      {/* Events 01 */}
      <div id="about-01">
        <div className="content-box-lg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center wow slideInDown">
                <div className="horizontal-heading">
                  <h2>
                    <strong>Events</strong>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row display-flex display-flex-event">
              <div
                className="col-lg-6 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                {/* About item 02 */}
                <div className="about-item community text-center">
                  <img src={Docker} alt="" />
                  <h3>Dive Into Docker</h3>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item community text-center">
                  <img src={Resume} alt="" />
                  <h3>Resume Writing and Job Hunting</h3>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-12  wow fadeInRight"
                style={{ flexBasis: "fit-content" }}
              >
                <div className="about-item  community text-center">
                  <img src={CyberSecurity} alt="" />
                  <h3>Cyber Security 101</h3>
                </div>
              </div>
            </div>

            <br/>

            <div className="row display-flex display-flex-event">
              <div
                className="col-lg-6 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-duration="2s"
                style={{ flexBasis: "fit-content" }}
              >
                {/* About item 02 */}
                <div className="about-item community-2 text-center">
                  <h3>Stay Tuned for Upcoming Events</h3>
                  <img src={ComingSoon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 02 Ends */}
    </section>
  );
};

export default Events;
